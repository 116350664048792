<template>
  <b-card v-if="file_content">
    <b-card-title>
      <!-- TERMINI & CONDIZIONI -->
      <!-- <button @click="goBack" style="float: right;">Indietro</button> -->
    </b-card-title>
    <b-card-text v-html="renderedMarkdown" />
  </b-card>
  <b-card v-else>
    <b-card-title>
      {{error_message ? 'Errore' : 'Attendere'}}
      <!-- <button @click="goBack" style="float: right;">Indietro</button> -->
    </b-card-title>
    <b-card-text>
      {{error_message ? error_message : "Loading..."}}
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCardTitle
} from 'bootstrap-vue'
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

export default {
  data() {
    return {
      file_content: "",
      error_message: "",
    };
  },
  mounted() {
    this.getTermsConditions();
  },
  computed: {
    azToken() {
      return this.$route.params.token;
    },
    renderedMarkdown() {
      return md.render(this.file_content);
    },
  },
  methods: {
    getTermsConditions() {
      this.$http.get('/segnalazione/terms/' + this.azToken).then((res) => {
        if (res && res.status === 200) {
          this.file_content = res.data;
        } else {
          this.error_message = res.data.error || 'Qualcosa è andato storto, riprovare';
        }
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          this.error_message = error.response.data.error;
        } else {
          this.error_message = 'Errore sconosciuto';
        }
      });
    },
    goBack() {
      this.$router.go(-1); // Go back to the previous route
    },
  },
  components: {
    BCard,
    BCardText,
    BCardTitle,
  },
};
</script>
